import styled from 'styled-components'
import { size } from '../../../constants'

export const Content = styled.div`
  padding-top: 36px;
  padding-bottom: 32px;

  @media (max-width: ${size.md}) {
    padding-top: 48px;
    padding-bottom: 24px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 32px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`
