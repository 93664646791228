import fullSizeCardImgSVG from '../../../../static/img/psychotherapist/fullSizeCardImg.svg'
import styled from 'styled-components'

const paragraphs = [
  'Но на практике оказывается не совсем так: психотерапией занимаются как врачи-психиатры, так и психологи, прошедшие повышение квалификации в психотерапевтическом подходе.',
  'Поэтому их разделяют на врачей-психотерапевтов и немедицинских психотерапевтов. Их нельзя разграничить по эффективности психотерапевтической работы, так как это будет зависеть от квалификации конкретного специалиста.',
  'В нашем сервисе работают только немедицинские психотерапевты. Они не ставят диагнозыи не выписывают лекарства, но эффективно помогают разговорной психотерапией.'
]

const FullSizeCardImgSVG = styled.img.attrs(() => ({
  src: fullSizeCardImgSVG
}))``

const differenceFullSizeData = {
  image: FullSizeCardImgSVG,
  paragraphs
}

export default differenceFullSizeData
