import App from '../components/App'
import AreYouIllAnswer from '../organisms/Psychotherapist/AreYouIllAnswer/AreYouIllAnswer'
import BeSureItHelps from '../organisms/Psychotherapist/BeSureItHelps'
import Difference from '../organisms/Psychotherapist/Difference'
import FindSpecialistRightNow from '../organisms/Psychotherapist/FindSpecialistRightNow'
import Footer from '../components/Footer'
import Header from '../components/Header'
import HowToFind from '../organisms/Psychotherapist/HowToFind'
import HowToRecognizeNeed from '../organisms/Psychotherapist/HowToRecognizeNeed'
import ImageTitleDesc from '../organisms/Psychotherapist/ImageTitleDesc'
import ListFromItems from '../organisms/Psychotherapist/ListFromItems'
import React from 'react'
import SEO from '../atoms/SEO'
import SecondBlock from '../organisms/Psychotherapist/SecondBlock'
import WhenToConsult from '../organisms/Psychotherapist/WhenToConsult'
import WhyHelp from '../organisms/Psychotherapist/WhyHelp'
import styled from 'styled-components'

import { AnexityArticles } from '../organisms/AnexityArticles'
import {
  AreYouIllSvg,
  ImageWrapper,
  ImageWrapper_2,
  PsychotherapistMainSvg,
  PsychotherapistSessionSvg
} from '../organisms/Psychotherapist/ImageTitleDesc/ImageTitleDesc.styles'
import { CommentsSection } from '../organisms/CommentsSection'
import { Event as GAEvent } from '../components/GA'
import { TariffBlock } from '../organisms/SeoTariffsAndBundles/TariffBlock'
import { TarrifPackages } from '../organisms/SeoTariffsAndBundles/TarrifPackages'
import { Title } from '../atoms/Title'
import {
  WHY_NEED_PSYCHOTHERAPIST,
  areYouIllTitle,
  consultationDescription,
  consultationListItems,
  psychotherapistsHelpTitle,
  psychotherapistsSessionListItems,
  psychotherapistsSessionTitle,
  selectPsychotherapist,
  whyNeedPsychotherapistParagraphs
} from '../organisms/Psychotherapist/constants/constants'

import { graphql } from 'gatsby'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: calc(var(--vh, 1vh) * 100);
  background: #e6f0fd;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.div`
  & section {
    max-width: 100vw;
  }
`

const psychotherapistMainImage = (
  <ImageWrapper size={size.lg}>
    <PsychotherapistMainSvg />
  </ImageWrapper>
)

const AreYouIllImage = (
  <ImageWrapper_2 size={size.md}>
    <AreYouIllSvg />
  </ImageWrapper_2>
)

const psychotherapistSessionimage = (
  <ImageWrapper size={size.md}>
    <PsychotherapistSessionSvg />
  </ImageWrapper>
)

const button = {
  name: selectPsychotherapist,
  href: '/wizard/',
  onClick: GAEvent.openSemeinyiPageFirstScreen
}

const Psychotherapist = ({ data }) => (
  <App>
    <SEO
      description="Онлайн-консультации психотерапевта ✔Более 280 специалистов ✔Онлайн сессия от 2000 ₽ ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему"
      title="Подбор и консультация психотерапевта онлайн — найти и записаться на психотерапию | YouTalk"
    />
    <Page>
      <Header />
      <Main>
        <ImageTitleDesc
          TitleComponent={Title.H1}
          directionChangeSize={size.lg}
          descTitle={consultationDescription}
          title={psychotherapistsHelpTitle}
          content={<ListFromItems items={consultationListItems} />}
          button={button}
          image={psychotherapistMainImage}
          styles={{ buttonDisplayChangeSize: size.md }}
        />

        <SecondBlock />

        <WhenToConsult />

        <HowToRecognizeNeed />

        <ImageTitleDesc
          styles={{ youIllPaddingTop: '68px', background: 'white' }}
          TitleComponent={Title.H3asH2styles}
          directionChangeSize={size.md}
          title={areYouIllTitle}
          content={<AreYouIllAnswer />}
          image={AreYouIllImage}
        />

        <Difference />

        <WhyHelp
          title={WHY_NEED_PSYCHOTHERAPIST}
          paragraphs={whyNeedPsychotherapistParagraphs}
        />

        <BeSureItHelps />

        <FindSpecialistRightNow data={data} />

        <TariffBlock />
        <TarrifPackages />

        <HowToFind />

        <CommentsSection page={'Psychotherapist'} />

        <AnexityArticles data={data.articles} />

        <ImageTitleDesc
          TitleComponent={Title.H2}
          directionChangeSize={size.md}
          title={psychotherapistsSessionTitle}
          content={<ListFromItems items={psychotherapistsSessionListItems} />}
          button={button}
          image={psychotherapistSessionimage}
          styles={{ buttonDisplayChangeSize: size.md }}
        />
      </Main>
      <Footer />
    </Page>
  </App>
)

export default Psychotherapist

export const query = graphql`
  query Psychotherapist {
    articles: allMdx(
      sort: { order: DESC, fields: frontmatter___created_at }
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { tags: { elemMatch: { title: { eq: "тревога" } } } }
      }
    ) {
      totalCount
      nodes {
        frontmatter {
          announcement
          created_at(formatString: "DD.MM.YYYY")
          color
          title
          author
          tags {
            title
          }
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        slug
        id
      }
    }

    psychologists: allPsychologistsData(filter: { is_active: { eq: true } }) {
      edges {
        node {
          id
          _id
          is_active
          about
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photo {
            ext
            hash
            url
          }
          professions {
            profession_name
          }
          self_appointment
          sync_messages
          start_of_practice
          video
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
        }
      }
    }
  }
`
