import psychologistSVG from '../../../../static/img/psychotherapist/psychologist.svg'
import psychotherapist_2SVG from '../../../../static/img/psychotherapist/psychotherapist_2.svg'
import psychotherapySVG from '../../../../static/img/psychotherapist/psychotherapy.svg'
import styled from 'styled-components'

const images = [psychologistSVG, psychotherapySVG, psychotherapist_2SVG]

const titles = ['Психолог', 'Психотерапия', 'Психотерапевт']

const paragraphs = [
  '«Психолог» — собирательное название для многих профессий сфер работы (организационный психолог, педагог-психолог, психолог-консультант, социальный психолог, клинический психолог и т.д).',
  'Формат работы, в котором специалист использует методыи техники психотерапевтического подхода, чтобы помочь клиенту разрешить какую-либо проблему.',
  'Согласно приказу «О психотерапевтической помощи», психотерапевт — это врач-психиатр, прошедший дополнительную подготовку по психотерапии.'
]

const imagesSVGArray = images.map(
  (image) =>
    styled.img.attrs(() => ({
      src: image
    }))``
)

const differences = images.map((_, index) => {
  return {
    image: imagesSVGArray[index],
    title: titles[index],
    paragraph: paragraphs[index]
  }
})

export default differences
