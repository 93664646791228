import BlockContainer from '../BlockContainer/BlockContainer'
import ButtonNewPrimary from '../common/ButtonNewPrimary'
import React, { useMemo } from 'react'
import { Content } from './FindSpecialistRightNow.styles'
import {
  FIND_SPECIALIST_PARAGRAPH,
  FIND_SPECIALIST_TITLE,
  selectPsychotherapist
} from '../constants/constants'
import { Event as GAEvent } from '../../../components/GA'
import { Psychotherapists } from '../Psychotherapists'
import { color } from '../../../styles/vars/colors'
import { isBrowser } from '../../../atoms/root'
import { randomChoice } from '../../../atoms/randomChoice'
import { selectDeviceSize } from '../../../state/reducers/deviceSlice'
import { size } from '../../../constants'
import { useSelector } from 'react-redux'

const getFeatures = (psychologists) =>
  psychologists.map((psychologist) => ({
    ...psychologist,
    features: (psychologist.work_areas ?? psychologist.workAreas)
      .map(({ tag }) => tag)
      .join('\n')
  }))

const FindSpecialistRightNow = ({ data }) => {
  const windowWidth = useSelector(selectDeviceSize)

  const psychologists = useMemo(
    () => randomChoice(data.psychologists.edges.map(({ node }) => node)),
    [data.psychologist]
  )

  const countByWindowWidth = useMemo(
    () => (windowWidth < 1023 ? 2 : 3),
    [windowWidth]
  )

  const psychologistSliced = useMemo(
    () =>
      isBrowser ? getFeatures(psychologists.slice(0, countByWindowWidth)) : [],
    [countByWindowWidth]
  )

  const buttonComponent = (
    <ButtonNewPrimary
      styles={{ justifyContent: 'center', buttonDisplayChangeSize: size.md }}
      href={'/wizard/'}
      name={selectPsychotherapist}
      onClick={GAEvent.openSemeinyiPageFirstScreen}
    />
  )

  return (
    <BlockContainer
      ContentComponent={Content}
      content={<Psychotherapists psychologists={psychologistSliced} />}
      style={{ backgroundColor: color.text.white }}
      title={FIND_SPECIALIST_TITLE}
      paragraph={FIND_SPECIALIST_PARAGRAPH}
      button={buttonComponent}
    />
  )
}

export default FindSpecialistRightNow
