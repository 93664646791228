export const consultationDescription =
  'Консультация с высококлассным психотерапевтом онлайн поможет:'
export const psychotherapistsHelpTitle =
  'Психотерапевты YouTalk помогут достичь ваших целей'
export const psychotherapistsSessionTitle =
  'Сессии с психологами YouTalk проходят онлайн'
export const areYouIllTitle =
  'Означает ли обращение к психотерапевту, что у меня психическое расстройство?'
export const beSureItHelpsTitle = 'Как понять, что психотерапия помогает?'
export const beSureItHelpsDescription =
  'Чтобы отследить, помогает ли вам психотерапия, вы можете задать себе несколько простых вопросов:'
export const selectPsychotherapist = 'Подобрать психотерапевта'

export const consultationListItems = [
  'улучшить качество жизни',
  'справиться с проблемами',
  'улучшить настроение и отношения с близкими',
  'вылечить психические расстройства'
]

export const psychotherapistsSessionListItems = [
  'Вы назначаете сессию в удобное время',
  'Экономите время на дорогу до кабинета и обратно',
  'Работаете с психологом в комфортной и привычной обстановке',
  'Можете бесплатно отменить или перенести сессию за 24 часа'
]

export const beSureItHelpsListItems = [
  'Вижу ли я прогресс относительно своего первоначального запроса?',
  'Научился ли я чему-то, чего раньше не умел?',
  'Стал ли я лучше понимать себя, свои мысли, эмоции, поведение? Вижу ли я в них какие-то изменения?',
  'Улучшилось ли как-то качество моей жизни?',
  'Как я себя чувствую?',
  'Возможно, параллельно обнаружились или решились какие-то проблемы, которые я изначально не планировал прорабатывать в терапии?'
]

export const IMPORTANT = 'Важно'
export const IMPORTANT_PARAGRAPH =
  'В этой статье мы имеем в виду, что психотерапевт — это специалист с психологическим или медицинским образованием, который проводит психотерапию с использованием научно признанных подходов.'

export const HOW_FAST_IT_HELPS = 'Как быстро начинает помогать психотерапия?'
export const HOW_FAST_IT_HELPS_PARAGRAPH =
  'Длительность терапии и первые результаты очень индивидуальны: влияет ваш запрос, предыдущий опыт, ваши навыки рефлексии и т.д. Но обычно первые небольшие результаты можно будет заметить уже после 2-3 консультации.'

export const CONSULT_PSYCHOTHERAPIST =
  'В таких случаях стоит обратиться не только к психологу, но и психиатру'
export const DO_YOU_NEED_PSYCHOTHERAPIST =
  'Мы сделали чек-лист с частыми запросами к психологам, чтобы вы могли соотнести их со своей жизнью и решить, нужна ли вам в данный момент психотерапия'

export const DOWNLOAD_CHECKLIST = 'Скачать чек-лист'

export const WHY_NEED_PSYCHOTHERAPIST =
  'Почему стоит получить помощь онлайн-психотерапевта в YouTalk?'
export const whyNeedPsychotherapistParagraphs = [
  'Все наши психологи — компетентные специалисты, которые имеют профильное образование и опыт работы от трех лет. Всего 6% психотерапевтов проходят отбор в нашу команду.',
  'В YouTalk вы можете назначить консультацию на любое удобное время, так как наши психологи работают в разных часовых поясах. Если у вас есть специфичные пожелания по времени — озвучьте их консультанту-диагносту.',
  'Консультации в YouTalk анонимны. Мы спрашиваем только ваш контакт для связи с психотерапевтом и администратором. Вы можете оставить при себе всю личную информацию, если захотите.'
]

export const RECOGNIZE_NEED_TITLE =
  'Как понять, что мне действительно нужен психотерапевт?'
export const RECOGNIZE_NEED_PARAGRAPH =
  'Тревожными звоночками о том, что поход к специалисту точно не стоит откладывать, будут симптомы, значительно влияющие на качество вашей жизни'

export const WHEN_TO_CONSULT_TITLE = 'Когда нужна помощь психотерапевта?'
export const WHEN_TO_CONSULT_PARAGRAPH =
  'Фактически в психотерапию можно прийти с любым запросом, касающимся вас напрямую: что-то в вашей жизни изменить, убрать или добавить. Психологи нередко делят запросы на три большие группы:'

export const FIND_SPECIALIST_TITLE =
  'Подберите специалиста и запишитесь прямо сейчас!'
export const FIND_SPECIALIST_PARAGRAPH =
  'Принять решение обратиться к психотерапевту может быть сложно, но сотрудники YouTalk поддержат вас на этом пути. Заполните анкету — и консультант-диагност найдет для вас наиболее подходящего психолога, или же выберите его самостоятельно через наш алгоритм.'

export const HOW_TO_FIND_TITLE =
  'Как найти и подобрать психотерапевта с помощью нашего сервиса'

export const DIFFERENCE_TITLE = 'Чем отличается психотерапевт от психолога?'

export const FILL_THE_FORM_TITLE =
  '1. Заполните анкету. 10 вопросов, которые займут 6 минут'
export const FILL_THE_FORM_DESC = 'Укажите ключевые моменты:'
export const FILL_THE_FORM_BTN_NAME = 'Заполнить анкету'

export const fillTheFormListItems = [
  'Как вы себя чувствуете последнее время?',
  'С чем нужна помощь — например, с карьерой, отношениями или переживанием потери?',
  'Важен ли вам пол и возраст психолога, а также подход, в котором работает специалист? '
]

export const CHOOSE_START_TIME_TITLE = '3. Выберите дату и времяпервой сессии '
export const CHOOSE_START_TIME_BTN_NAME = 'Начать терапию'

export const chooseStartListItems = [
  'Зарегистрируйтесь на платформе YouTalk при помощи номера телефона. ',
  'Затем оплатите первую сессию — это гарантирует, что выбранное время у специалиста будет зарезервировано для вас. Принимаются российские и зарубежные карты. ',
  'Сразу после оплаты мы отправим вам SMS или сообщение в WhatsApp с памяткой для подготовки к первой сессии. '
]

export const ALGORITHM_FINDS_Title =
  '2. Алгоритм подберет подходящих специалистов'
export const ALGORITHM_FINDS_PARAGRAPH =
  'Изучите анкеты и выберите психолога, с  которым хотите начать терапию.'

export const MANAGE_SCHEDULE_Title =
  '4. Управляйте расписанием в личном кабинете'
export const MANAGE_SCHEDULE_PARAGRAPH =
  'Выбирайте дату и время сессии, оплачивайте и переносите встречи.'
