import PsychologistMiniCardTherapy from './PsychologistMiniCardTherapy'
import React from 'react'
import styled, { css } from 'styled-components'

import { Grid } from '../../atoms/Grid'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'
import { size } from '../../constants'

const gridCardsCSS = css`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 384px);
  grid-template-rows: ${(props) =>
    props.rows ? `repeat(${props.rows}, 1fr)` : 4};
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  @media (max-width: ${size.lg}) {
    grid-template-columns: repeat(3, 304px);
  }

  @media (max-width: ${size.md}) {
    grid-template-columns: repeat(2, 356px);
    grid-gap: 16px;
  }

  @media (max-width: ${size.sm}) {
    grid-template-columns: repeat(1, 100%);
    grid-gap: 16px;
  }
`

const GridCards = styled(({ className, children }) => (
  <div className={className} css={gridCardsCSS}>
    {children}
  </div>
))`
  margin-bottom: ${({ marginBottom }) => marginBottom?.marginBottom || '48px'};

  @media (max-width: ${size.md}) {
    margin-bottom: ${({ marginBottom }) => marginBottom?.md || '40px'};
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: ${({ marginBottom }) => marginBottom?.sm || '32px'};
  }
`

const marginBottom = {
  marginBottom: '0',
  md: '0',
  sm: '0'
}

export const Psychotherapists = styled(({ className, psychologists }) => {
  return (
    <section className={className}>
      <Grid>
        <GridCards marginBottom={marginBottom}>
          {psychologists.map((psychologist) => (
            <PsychologistMiniCardTherapy
              key={psychologist.id}
              psychologist={psychologist}
            />
          ))}
        </GridCards>
      </Grid>
    </section>
  )
})`
  background: ${color.text.white};

  & ${PsychologistMiniCardTherapy} {
    background: ${color.background};
    height: 468px;
  }

  & ${Title.H2asH1styles} {
    margin-bottom: 32px;

    @media (max-width: ${size.md}) {
      margin-bottom: 24px;
    }
  }
`
