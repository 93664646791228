import ImproveLifeQualitySVG from '../../../../static/img/psychotherapist/ImproveLifeQuality.svg'
import livingTheSituationSVG from '../../../../static/img/psychotherapist/livingTheSituation.svg'
import mentalProblemsSVG from '../../../../static/img/psychotherapist/mentalProblems.svg'
import styled from 'styled-components'

const images = [ImproveLifeQualitySVG, livingTheSituationSVG, mentalProblemsSVG]

const titles = [
  'Улучшение качества жизни',
  'Проживание ситуации',
  'Ментальные проблемы'
]

export const improveLifeQualityItems = [
  'Вы хотите стать увереннее в себе и своих силах',
  'Вам хочется найти своё призвание, место в жизни или даже хобби ',
  'Вы хотите улучшить сексуальную жизнь ',
  'У вас есть вопросы по воспитанию детей',
  'В вашей жизни предстоят значимые перемены и вам нужна поддержка',
  'У вас есть сложности в отношениях с деньгами',
  'Вам нужна мотивация и новые силы',
  'Вам интересно разобраться в себе и причинах каких-то поступков, событий'
]

export const livingTheSituationItems = [
  'Вы находитесь в конфликтной ситуации и не знаете, как из неё выйти',
  'Вы столкнулись с травмирующим событием и тяжело его переживаете',
  'Ваш близкий человек заболел/переживает тяжелый период, а вы не понимаете, как его поддержать ',
  'Вы недавно пережили тяжелый разрыв отношений',
  'Вы находитесь в абьюзивных/созависимых отношениях'
]

export const mentalProblemsItems = [
  'У вас часто без причины меняется настроение',
  'Вам ничего не хочется, вы чувствуете усталостьи апатию даже после полноценного отдыха',
  'Вам стало трудно сосредоточиться и сконцентрироваться',
  'В течение дня вы ощущаете постоянную сонливость',
  'У вас появились непонятные ощущения в теле, что-то трудно описуемое',
  'Вы стали замкнутым, перестали общаться с важными для вас людьми',
  'Все ваши отношения развиваются по одному и тому же сценарию',
  'Вы наносите себе физический вред',
  'Вы чувствуете, что перестали справляться с жизненными трудностями'
]

const items = [
  improveLifeQualityItems,
  livingTheSituationItems,
  mentalProblemsItems
]

const imagesSVGArray = images.map(
  (image) =>
    styled.img.attrs(() => ({
      src: image
    }))``
)

const whenToConsultCardsData = images.map((_, index) => {
  return {
    image: imagesSVGArray[index],
    title: titles[index],
    items: items[index]
  }
})

export default whenToConsultCardsData
