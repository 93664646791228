import areNotAbleToWorkSVG from '../../../../static/img/psychotherapist/areNotAbleToWork.svg'
import canNotSleepSVG from '../../../../static/img/psychotherapist/canNotSleep.svg'
import characterChangeSVG from '../../../../static/img/psychotherapist/characterChange.svg'
import cryingALotSVG from '../../../../static/img/psychotherapist/cryingALot.svg'
import dontWannaTalkSVG from '../../../../static/img/psychotherapist/dontWannaTalk.svg'
import moodIsBadSVG from '../../../../static/img/psychotherapist/moodIsBad.svg'
import moodIsUnstableSVG from '../../../../static/img/psychotherapist/moodIsUnstable.svg'
import seeingTheUnrealSVG from '../../../../static/img/psychotherapist/seeingTheUnreal.svg'
import suicidalThoughtsSVG from '../../../../static/img/psychotherapist/suicidalThoughts.svg'
import thoughtsOfHurtingOthersSVG from '../../../../static/img/psychotherapist/thoughtsOfHurtingOthers.svg'
import tooSuspiciousSVG from '../../../../static/img/psychotherapist/tooSuspicious.svg'
import usingTooMuchAlcoholSVG from '../../../../static/img/psychotherapist/usingTooMuchAlcohol.svg'

import styled from 'styled-components'
import { size } from '../../../constants'

const images = [
  moodIsBadSVG,
  moodIsUnstableSVG,
  cryingALotSVG,
  dontWannaTalkSVG,
  areNotAbleToWorkSVG,
  canNotSleepSVG,
  suicidalThoughtsSVG,
  seeingTheUnrealSVG,
  characterChangeSVG,
  tooSuspiciousSVG,
  thoughtsOfHurtingOthersSVG,
  usingTooMuchAlcoholSVG
]

const paragraphs = [
  'Ваше настроение стабильно «ниже среднего» уже больше 2-х недель',
  'Ваше настроение меняется без причины несколько раз за день',
  'Вы замечаете, что плачете значительно чаще, чем раньше, или уже совсем не можете плакать',
  'Вы гораздо меньше интересуетесь общением, чем обычно',
  'Тревога мешает вам выполнять повседневные дела и рабочие задачи',
  'Вас мучает бессонница, частые ночные просыпания или постоянная сонливость',
  'У вас появляются мысли или намерения нанести себе вред или убить себя',
  'Вы слышите или видите то, что нереально для других людей',
  'Вы или ваши близкие отмечаете, что ваш характер значительно изменился за короткое время без причины',
  'Вы стали более подозрительным',
  'Вы думаете о том, чтобы причинить вред другим людям',
  'Вы замечаете, что чаще употребляете алкоголь или другие психоактивные вещества'
]

const imagesSVGArray = images.map(
  (image) =>
    styled.img.attrs(() => ({
      src: image
    }))`
      @media (max-width: ${size.xs}) {
        width: 78px;
        height: 78px;
      }
    `
)

const signsOfPsychotherapistNeed = paragraphs.map((_, index) => {
  return {
    image: imagesSVGArray[index],
    paragraph: paragraphs[index]
  }
})

export default signsOfPsychotherapistNeed
